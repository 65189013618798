import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-screenshots-section-gallery',
  templateUrl: './project-screenshots-section-gallery.component.html',
  styleUrls: ['./project-screenshots-section-gallery.component.css']
})
export class ProjectScreenshotsSectionGalleryComponent implements OnInit {

  @Input() projects: any;

  constructor() { }

  ngOnInit(): void {
  }

}
