<div class="page-wraper">

  <!-- HEADER START -->
  <app-section-header></app-section-header>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- SECTION CONTENTG START -->

         <!-- CONTACT FORM -->
       <div class="section-full  p-t80 p-b50 bg-cover" style="background-image:url(assets/images/contact-page/contact-page-background.jpg)">
          <div class="section-content">
              <div class="container">
                  <div class="contact-one">
                      <!-- CONTACT FORM-->
                      <div class="row  d-flex justify-content-center flex-wrap">

                          <!-- <div class="col-lg-6 col-md-6 m-b30">
                                  <div class="section-head left wt-small-separator-outer">
                                      <div class="wt-small-separator site-text-primary">
                                          <div class="sep-leaf-left"></div>
                                          <div>Contact Form</div>
                                          <div class="sep-leaf-right"></div>
                                      </div>
                                      <h2>Get In Touch</h2>
                                  </div>
                                  <form method="post" (ngSubmit)="onSubmit()">
                                  <div class="row">
                                     <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                              <input id="username" name="username" type="text" required class="form-control" placeholder="Name">
                                          </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                             <input name="email" id="email" type="text" class="form-control" required placeholder="Email">
                                          </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                              <input id="phone" name="phone" type="text" class="form-control" required placeholder="Phone">
                                           </div>
                                      </div>

                                      <div class="col-lg-12 col-md-12">
                                          <div class="form-group">
                                               <input id="subject" name="subject" type="text" class="form-control" required placeholder="Subject">
                                           </div>
                                      </div>

                                      <div class="col-md-12">
                                          <div class="form-group">
                                             <textarea id="message" name="message" class="form-control" rows="4" placeholder="Message"></textarea>
                                           </div>
                                      </div>

                                     <div class="col-md-12">
                                          <button class="site-button site-btn-effect" type="submit">Submit Now</button>
                                      </div>
                                  </div>
                                </form>
                          </div> -->

                          <div class="col-lg-6 col-md-6 m-b30">
                              <div class="contact-info">
                                  <div class="contact-info-inner">

                                      <!-- TITLE START-->
                                      <div class="section-head left wt-small-separator-outer">
                                          <div class="wt-small-separator site-text-primary">
                                              <div class="sep-leaf-left"></div>
                                              <div>Contact info</div>
                                              <div class="sep-leaf-right"></div>
                                          </div>
                                          <h2>Our Full Info</h2>
                                      </div>
                                      <!-- TITLE END-->

                                      <div class="contact-info-section" style="background-image:url(assets/images/background/bg-map2.png);">

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Phone number</h3>
                                                      <p><a href="tel:+91-9500855901">+91-9500855901</a></p>
                                                  </div>
                                              </div>

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Email address</h3>
                                                      <p>info@primeelectriks.com</p>
                                                  </div>
                                              </div>

                                              <div class="wt-icon-box-wraper left m-b30">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Address info</h3>
                                                      <p>Prime Electriks and Machinery Services Pvt Limited<br>
                                                        No.7/79B Vijayanagari, Swamythoppu,<br>
                                                        Agasteeswaram Taluk, Kanyakumari District,<br>
                                                        Tamil Nadu – 629 704.</p>
                                                  </div>
                                              </div>

                                              <div class="wt-icon-box-wraper left">

                                                  <div class="icon-content">
                                                      <h3 class="m-t0 site-text-primary">Opening Hours</h3>
                                                      <ul class="list-unstyled m-b0">
                                                        <li>Mon-Sat: 9 am – 6 pm</li>
                                                        <li>Sunday: Closed</li>
                                                      </ul>
                                                  </div>
                                              </div>

                                      </div>

                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>

              </div>
          </div>
      </div>

         <!-- GOOGLE MAP -->
       <div class="section-full bg-white p-tb80">
          <div class="section-content">
              <div class="container">
                  <div class="gmap-outline">
                      <!-- <div id="gmap_canvas2" class="google-map"></div> -->
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15798.159929434149!2d77.55047730000001!3d8.1482225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f3920af282e9%3A0x72deac84d00141de!2sPrime%20Electriks%20and%20Machinery%20Services%20Pvt%20Limited!5e0!3m2!1sen!2sin!4v1658058981420!5m2!1sen!2sin" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
              </div>
          </div>
      </div>

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer></app-section-footer>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
