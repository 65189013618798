<div class="section-full p-t80 p-b50 bg-white team-bg-section-outer bg-no-repeat {{bgcover=='1' ? 'bg-cover' : 'bg-center'}}" style="background-image:url({{data.bgImage}})">
    <div class="container">
        <div class="section-content">

        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center">
            <div class="wt-small-separator site-text-primary">
                <div  class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
                <div  class="sep-leaf-right"></div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 m-b30" *ngFor="let object of data.members">
                    <div class="wt-team-1 {{grayBox=='0'? 'p-a5 bg-white': ''}}">

                        <div class="wt-media">
                            <img src="{{object.image}}" alt="">
                        </div>

                        <div class="wt-info {{grayBox=='0'? 'p-a15': 'p-a30 bg-gray'}}">
                            <div class="team-detail  text-center">
                                <h3 class="m-t0 team-name"><a routerLink="{{object.redirectionLink}}" class=" site-text-secondry">{{object.name}}</a></h3>
                                <span class="title-style-2 team-position site-text-primary">{{object.post}}</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        </div>
    </div>
</div>
