<div class="page-wraper">

  <!-- HEADER START -->
  <app-section-header></app-section-header>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- GALLERY SECTION START -->
      <app-section-gallery3 [data]="images"></app-section-gallery3>
      <!-- GALLERY SECTION END -->

      <div class="section-full p-t80 p-b50">
          <div class="container">
              <div class="row d-flex justify-content-center flex-wrap">

                   <div class="col-lg-4 col-md-12 m-b30">
                      <div class="project-detail-left bg-gray p-a30">

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-tachometer"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Project</h3>
                                  <p>{{details.project}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-list-alt"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Categories</h3>
                                  <p>{{details.category}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left  m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-calendar"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Time</h3>
                                  <p>{{details.time}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-tags"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Status</h3>
                                  <p>{{details.status}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-user"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Client</h3>
                                  <p>{{details.client}}</p>
                              </div>
                          </div>

                      </div>
                  </div>

                   <div class="col-lg-8 col-md-12 m-b30">
                      <div class="project-detail-right">
                          <h2 class="m-t0">Kudankulam Nuclear Power Plant</h2>
                          <p>Kudankulam Nuclear Power Plant (or Kudankulam NPP or KKNPP) is the largest nuclear power station in India, situated in Kudankulam in the Tirunelveli district of the southern Indian state of Tamil Nadu. </p>
                          <p>Construction on the plant began on 31 March 2002, but faced several delays due to opposition from local fishermen. KKNPP is scheduled to have six VVER-1000 reactors built in collaboration with Atomstroyexport, the Russian state company and Nuclear Power Corporation of India Limited (NPCIL), with an installed capacity of 6,000 MW of electricity.</p>

                          <h3 class="m-t0">Delivery of Services that we completed</h3>
                          <ul class="list-check-circle primary">
                              <li>Installation of 11KV, 630A Switch gear along 2 nos X 2.5MVA Transformers which is sourcing power demand of Crusher Plant.</li>
                              <li>Handled 1650 meters of cable trenches with sand bedding and laying of 3X300 sq.mm 11KV cable laying.</li>
                              <li>Installation and Commisioning of temporary and main lightning networks.</li>
                              <li>Installation of MV Switchgears, LDB's for entire lightning networks.</li>
                              <li>Fabrication and Erection of 50NB-200NB galvanized pipelines.</li>
                          </ul>
                      </div>
                  </div>

              </div>
         </div>
      </div>

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer></app-section-footer>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
