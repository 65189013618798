<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Annual Maintenance Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
       <p>Strong Technical skills to carry out Erection, Testing, Commissioning, Maintenance, Operation, Project Management, Shutdown activities in Power industries. Total Productive Maintenance (TPM) is a process that enrolls the entire organization in asset management responsibility.</p>
       <p>This is achieved by utilizing problem-solving teams called Equipment Improvement Teams, engaging the operator in “basic care “of the equipment through Autonomous Maintenance, focusing improvement efforts toward Maintenance Excellence, enhancing skills and knowledge, and improving how equipment is designed and manufactured.</p>
        <div class="wt-media m-b30">
               <img src="assets/images/services/services-annual-maintenance-services.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>Annual Maintenance of equipments and schemes by periodically on every year for substations, Power plants and all process plants. Also protection tends to major breakdowns which will cause huge loss in production as well as damages the system equipments. We could have been avoided that kind of breakdowns by proper maintenance of protective equipments and protection schemes.</p>    
        </div>
    </div>
   <div class="service-provide">
           <h2>List of Maintenance Services</h2>
        <div class="row">
        
            <div class="col-md-6">
                <ul class="list-angle-right">
                    <li>Annual Maintenance testing of relays, Current Transformers, Potential transformers, Circuit breakers, Earth pits, Transformers and calibration of meters during shutdown.</li>
                    <li>Periodical Maintenance</li>
                    <li>Breakdown Maintenance</li>
                    <li>Overhauling & Testing of CB’s</li>
                </ul>
             </div> 
                                                       
            <div class="col-md-6">
                <div class="wt-media m-b30">
                    <img src="assets/images/services/services-annual-maintenance-services-1.jpg" alt=""> 
                </div>                                            
            </div>

        </div>
   </div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s K Kumararaja Projects Ltd<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">Annual testing and maintenance of all substations-KKNPP</div>
         </div>
     </div>
     <div class="panel wt-panel">
         <div class="acod-head">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseTwo5" class="collapsed" data-parent="#accordion5" >
                For M/s Reliance Infra Limited<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseTwo5" class="acod-body collapse bg-gray">
             <div class="acod-content p-a15">
                <ul class="list-angle-right">
                    <li>Installation and commissioning of 250KVA, 30KVA & 500 KVA Diesel Generator</li>
                    <li>Maintenance of 500KVA Diesel generators</li>
                </ul>
             </div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>