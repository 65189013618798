import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-kudankulam-nuclear-power',
  templateUrl: './page-project-kudankulam-nuclear-power.component.html',
  styleUrls: ['./page-project-kudankulam-nuclear-power.component.css']
})
export class PageProjectKudankulamNuclearPowerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/banner-nuclear-power-plant.jpg",
    title: "Kudankulam Nuclear Power Plant",
    crumb: "Kudankulam Nuclear Power Plant"
  }

  images = [
    "assets/images/project/project-kudankulam-nuclear-power.jpg"
  ]

  details = {
    project: "Nuclear Power Plant",
    category: "Installation & Maintenance",
    time: "2 Years",
    status: "Completed",
    client: "L & T Infrastructure"
  }

}
