<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Electro Mechanical Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
       <p>Prime Electriks and Machinery Services provide complete installation, maintenance, testing and reassurance services in all sectors across South India. </p>
       <p>From small to large scale projects, we bring extensive experience and a superior quality work ethic to each and every task we undertake. By working closely with you and gaining a clear understanding of your vision and goals, we can expertly create bespoke solutions tailored to the demands of your project.</p>
           <div class="wt-media m-b30">
               <img src="assets/images/services/services-electromechanical.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>We employ only the highest standard of engineers and are proud to offer a diverse and exceptionally qualified workforce, complementing our non-exhaustive list of electrical and mechanical services.</p>
        </div>
    </div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s Larson & Toubro Ltd<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">
                <ul class="list-angle-right">
                    <li>Crusher plant – Conveyer Installation and Commissioning</li>
                    <li>Transformer OLTC, MOG maintenance works</li>
                    <li>Vacuum & Air Circuit breaker maintenance works</li>
                    <li>Heavy Machinery Boom Blazer, Millers, Tower Cranes, EOT Cranes maintenance</li>
                </ul>
             </div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>