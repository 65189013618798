<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header></app-section-header>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->
        
        <!-- OUR TEAM START -->
        <app-section-team1 [data]="team1"></app-section-team1>
        <!-- OUR TEAM SECTION END -->  
        
        <!-- OUR TEAM START -->
        <app-section-team2 [data]="team2" bgcover="0" grayBox="0"></app-section-team2>  
        <!-- OUR TEAM SECTION END --> 
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer></app-section-footer>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
