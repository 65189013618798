<div *ngFor="let data of visions">
  <div class="section-full  p-t80 bg-white  bg-bottom-right bg-no-repeat" style="background-image:url(assets/images/background/bg-5.png)">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-12 col-md-12">
                <!-- TITLE START-->
                <div class="left wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <p>{{data.description}}</p>
                </div>
                <!-- TITLE END-->
            </div>
       </div>
    </div>

</div>
</div>
