<div class="page-wraper">

  <!-- HEADER START -->
  <app-section-header></app-section-header>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- GALLERY SECTION START -->
      <app-section-gallery3 [data]="images"></app-section-gallery3>
      <!-- GALLERY SECTION END -->

      <div class="section-full p-t80 p-b50">
          <div class="container">
              <div class="row d-flex justify-content-center flex-wrap">

                   <div class="col-lg-4 col-md-12 m-b30">
                      <div class="project-detail-left bg-gray p-a30">

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-tachometer"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Project</h3>
                                  <p>{{details.project}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-list-alt"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Categories</h3>
                                  <p>{{details.category}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left  m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-calendar"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Time</h3>
                                  <p>{{details.time}}</p>
                              </div>
                          </div>

                          <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                              <div class="icon-sm m-b15">
                                  <span class="icon-cell  site-text-primary"><i class="fa fa-tags"></i></span>
                              </div>
                              <div class="icon-content">
                                  <h3 class="wt-tilte m-b5">Status</h3>
                                  <p>{{details.status}}</p>
                              </div>
                          </div>

                      </div>
                  </div>

                   <div class="col-lg-8 col-md-12 m-b30">
                      <div class="project-detail-right">
                          <h2 class="m-t0">Eattayapuram 230 MWE Solar Power Plant</h2>
                          <p>NTPC Ettayapuram Solar PV Park is a 230MW solar PV power project. It is planned in Tamil Nadu, India. The project is currently in permitting stage. It will be developed in single phase. Post completion of the construction, the project is expected to get commissioned in 2022.</p>

                          <h3 class="m-t0">Delivery of Services that we completed</h3>
                          <ul class="list-check-circle primary">
                            <li>Installation of 330W Photovoltaic module mounted structure erection, String cabling, DC Cablings, Earth grid works.</li>
                            <li>Installation of 12.MVA transformer with inverters of each blocks independently carries 14.5 MW Clean natural energy.</li>
                            <li>A Heavy 220KV pooling station is under readiness for exporting a clean energy to 220/400KV Grid Substation M/s Power Grid Corporation India Limited, Eattayapuram.</li>
                          </ul>
                      </div>
                  </div>

              </div>
         </div>
      </div>

      <!-- GALLERY SECTION START -->
      <app-project-detailed-section-gallery [projectImages]="projectImages"></app-project-detailed-section-gallery>
      <!-- GALLERY SECTION END -->

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer></app-section-footer>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
