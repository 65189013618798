<div class="page-wraper">

  <!-- HEADER START -->
  <app-section-header></app-section-header>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- GALLERY SECTION START -->
      <app-project-screenshots-section-gallery [projects]="projects"></app-project-screenshots-section-gallery>
      <!-- GALLERY SECTION END -->

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer></app-section-footer>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
