import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.css']
})
export class PageContactComponent implements OnInit {

form: FormGroup;
name: FormControl = new FormControl("", [Validators.required]);
email: FormControl = new FormControl("", [Validators.required, Validators.email]);
message: FormControl = new FormControl("", [Validators.required, Validators.maxLength(256)]);
honeypot: FormControl = new FormControl("",);
submitted: boolean = false;
isLoading:boolean = false;
responseMessage: string;

  constructor(private formBuilder:FormBuilder, private http:HttpClient) {
    this.form = this.formBuilder.group({
      name: this.name,
      email: this.email,
      message: this.message,
      honeypot: this.honeypot
    })
   }

  ngOnInit(): void {

  }

  onSubmit(){
    if(this.form.status == "VALID" && this.honeypot.value == ""){
      this.form.disable();
      var formData: any = new FormData();
      formData.append("name", this.form.get("name").value);
      formData.append("email", this.form.get("name").value);
      formData.append("message", this.form.get("name").value);
      this.isLoading = true;
      this.submitted = false;
      var data = formData;
      // headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      // headers = headers.set('Access-Control-Allow-Origin', '*');
      var emailUrl = "https://script.google.com/macros/s/AKfycbw6MsCF6l-ysunyNq_G_-GKZmA0Kf7jiFq2JUgPoJnXjfI_-6hwr20Ur_O0eHkzgZWG/exec";
      this.http.post(emailUrl, data).subscribe(
        (response) =>{
          if(response["result"] == "success"){
            this.responseMessage = "Thanks for contacting"
          }else{
            this.responseMessage = "Something went wrong"
          }
          this.form.enable();
          this.submitted = true;
          this.isLoading = false;
          console.log("gmail response----->", response);
        },
        (error) => {
          this.responseMessage = "An error occured";
          this.form.enable();
          this.submitted = true;
          this.isLoading = false;
          console.log("gmail fail error----->", error);
        }
      )
    }
  }



  banner = {
    background: "assets/images/contact-page/contact-page-banner.jpg",
    title: "Contact Prime Electriks and Machinery Services",
    crumb: "Contact Prime Electriks and Machinery Services"
  }

}
