import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-about',
  templateUrl: './page-about.component.html',
  styleUrls: ['./page-about.component.css']
})
export class PageAboutComponent implements OnInit {

  @Input() overlay: any;

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/services/services-banner.jpg",
    title: "About Prime Electricks & Machinery Services",
    crumb: "About Prime Electricks & Machinery Services"
  }

  about = {
    title: "Welcome to Prime Electriks",
    subtitle: "Here are the reasons why you should choose Prime Electriks",
    image: "assets/images/about-page/about-page-workers.png",
    since: "2019",
    services: [
      {
        value: "Our prices are competitive and fair. There are no surprise bills. Any unexpected or additional expenses must be pre-approved by you. That’s how we would like to be treated, and that is how our clients are treated."
      },
      {
        value: "It’s our attention to the small stuff, scheduling of timelines and keen project management that makes us stand out from the rest."
      },
      {
        value: "We are chosen by professionals!"
      }
    ]
  }

  visions = [
    {
      title: "About Prime Electriks & Machinery Services",
      description: "Prime Electriks and Machinery Services Pvt Limited is an certified company, which was founded in the year 2019 by as a Proprietorship firm and later converted to private limited company. The company is enlisted for highly reliable Engineers and workmen’s with working equipments. We always provide a quality of services in respect of Electro Mechanical Services,Erection,Testing,Commissioning of and Operation and Maintenance of various power segments wide our country."
    },
    {
      title: "Our Vision",
      description: "We always satisfy our customer needs and maintaining good relationship with timely communication, dedication and courteous behavior. Maintaining safety aspects is only our MOTO, employees and customers is only our assets."
    },
    {
      title: "Our Mission",
      description: "We provide High Quality, Safe, Cost effective, Reliable Asset Management Solutions and Technical supports as per customer needs and satisfaction using world class best practices been delivering a quality of services at customers on committed time. A platform formed is completely team work for fulfilling our customer needs.Our potential delivers to customers satisfaction been highly reliable and quality."
    },
    {
      title: "Our values",
      description: "Our professional were competent to perform in Electrical and Instrumentation for power industries at all aspects. Also our professional been undergone world class safety training for keeping zero accidents at all times. Our professional were adapt to work in different atmosphere at work sites."
    }
  ]

  clients = {
    backgroundColor: "#FFFFFF",
    title: "Our Clients",
    subtitle: "Look at the our past clients we have worked with!",
    clients: [
      "assets/images/client-logo/l_and_t.png",
      "assets/images/client-logo/bgr_energy.png",
      "assets/images/client-logo/reliance_infra.png",
      "assets/images/client-logo/tangedco.png",
      "assets/images/client-logo/siemens.png",
      "assets/images/client-logo/department_of_atomic_energy.svg",
      "assets/images/client-logo/bhel.png",
      "assets/images/client-logo/tata_projects.jpg"
    ]
  }

  projects = {
    title: "Explore Our Recent Work",
    subtitle: "We've Done lot's Projects Let's Check Some Here",
    bgImage: "assets/images/home-page/home-page-all-services-bg.jpg",
    projects: [
      {
        category: "electrical-equipments-erection-and-commissioning",
        image: "assets/images/home-page/home-page-services-electrical-commisioning.jpg",
        title: "M/S Aditya Solar",
        pagelink: "services/electrical-equipments-erection-and-commissioning"
      },
      {
        category: "electro-mechanical-services",
        image: "assets/images/home-page/home-page-services-electro-mechanical.jpg",
        title: "M/S Larsen & Toubro",
        pagelink: "services/electro-mechanical-services"
      },
      {
        category: "lifting-and-transportation-services",
        image: "assets/images/home-page/home-page-services-lifting-and-transportation-1.jpg",
        title: "M/S Reliance Infra Limited",
        pagelink: "services/lifting-and-transportation-services"
      },
      {
        category: "supervision-services",
        image: "assets/images/home-page/home-page-services-supervison-services.jpg",
        title: "M/S BGR Energy Systems",
        pagelink: "services/supervision-services"
      },
      {
        category: "lifting-and-transportation-services",
        image: "assets/images/home-page/home-page-services-lifting-and-transportation-2.jpg",
        title: "M/S Macmet Engineering Ltd",
        pagelink: "services/lifting-and-transportation-services"
      },
      {
        category: "annual-maintenance-services",
        image: "assets/images/home-page/home-page-services-annual-maintenance-services-1.jpg",
        title: "M/S Kumararaja Projects Ltd",
        pagelink: "services/annual-maintenance-services"
      },
      {
        category: "annual-maintenance-services",
        image: "assets/images/home-page/home-page-services-annual-maintenance-services-2.jpg",
        title: "M/S Reliance Infra Ltd",
        pagelink: "services/annual-maintenance-services"
      },
      {
        category: "operation-and-maintenance-services",
        image: "assets/images/home-page/home-page-services-operation-and-maintenance-services.jpg",
        title: "M/S Larsen & Toubro Ltd",
        pagelink: "services/operation-and-maintenance-services"
      }
    ]
  }

  selfIntro = {
    image: "assets/images/home-page/home-page-industrial-worker.png",
    bgImage: "assets/images/background/footer-bg.png",
    bgClass: "bg-gray",
    bgCover: "",
    opacity: "opacity-09",
    imageClass: "self-intro-pic-block"
  }


  teams = [
    {
      image: "assets/images/team/rajesh.png",
      name: "A. Rajesh",
      speciality: "Management",
      description: "Rajesh is responsible for overseeing the strategic direction of the organization. He also involved in interacting with other leadership executives and setting precedence for the working culture and environment. After crafting the long-term vision, he usually looks to himself and other executive leadership to begin implementing those plans. Changes are often directly implemented by operational managers, but it is ultimately up to him to ensure the long-term plans are being followed through.",
      position: "CEO & Managing Director",
      level: "High",
      experience: "15 Years",
      phone: "+91-9698161200",
      phoneLink: "tel:+919698161200",
      email: "rajesh.a@primeelectriks.com",
      emailLink: "mailto:rajesh.a@primeelectriks.com",
      backgroundColor: "#FFFFFF"
    },
    {
      image: "assets/images/team/ganesan.jpg",
      name: "P. Ganesan",
      speciality: "Operations & Utilities",
      description: "Ganesan is responsible for formulate business strategy with others in the executive team and design policies that align with overall strategy. He coordinates customer service operations and find ways to ensure customer retention and ensuring compliance with local and international laws. He motivates teams to achieve productivity and engagement and report to CEO on operational performance and suggest improvements.",
      position: "Head - Operations & Utilities",
      level: "High",
      experience: "11 Years",
      phone: "+91-9489332511",
      phoneLink: "tel:+919489332511",
      email: "ganesan.p@primeelectriks.com",
      emailLink: "mailto:ganesan.p@primeelectriks.com",
      backgroundColor: "#f6f6f6"
    },
    {
      image: "assets/images/team/mathan.jpg",
      name: "B. Mathan",
      speciality: "Planning",
      description: "Mathan is responsible for monitoring project's budget, inventory, and production schedule and maintaining optimally efficient inventory of production materials. He creates planning studies and generates reports about new programs, plans, and regulations and reviews development proposals to ensure compliance with codes and regulations. He also manages all general planning issues for project.",
      position: "Manager - Planning ",
      level: "High",
      experience: "5 Years",
      phone: "+91-8903668378",
      phoneLink: "tel:+918903668378",
      email: "mathan.b@primeelectriks.com",
      emailLink: "mailto:mathan.b@primeelectriks.com",
      backgroundColor: "#FFFFFF"
    },
    {
      image: "assets/images/team/senthil_kumar.jpg",
      name: "S. Senthil Kumar",
      speciality: "Projects",
      description: "Senthil Kumar is responsible for building and developing the project team to ensure maximum performance, providing purpose, direction, and motivation. He also leads projects from requirements definition through deployment, identifying schedules, scopes, budget estimations, and project implementation plans, including risk mitigation. He coordinates with internal and external resources, ensuring projects remain within scope, schedule, and defined budgets, in collaboration with project staff from various functional departments",
      position: "Project Manager",
      level: "High",
      experience: "5 Years",
      phone: "+91-9489989065",
      phoneLink: "tel:+919489989065",
      email: "senthil.s@primeelectriks.com",
      emailLink: "mailto:senthil.s@primeelectriks.com",
      backgroundColor: "#f6f6f6"
    },
    {
      image: "assets/images/team/appathurai.jpg",
      name: "T. Appathurai",
      speciality: "Strategy & Accounts",
      description: "Appathurai is responsible for directing business planning efforts across all lines of business and functional areas and leads in the creation of frameworks and analysis to support strategic decisions. He also analyze on topics such as new market opportunities, strategic initiatives, business development, competitive landscape, and cross-business segment opportunities and develop strategic presentations and materials for use both internally and externally, including Investor Day and other conferences and forums.",
      position: "Head - Strategy & Accounts",
      level: "High",
      experience: "25 Years",
      phone: "+91-9489933500",
      phoneLink: "tel:+919489933500",
      email: "info@primeelectriks.com",
      emailLink: "mailto:info@primeelectriks.com",
      backgroundColor: "#FFFFFF"
    }
  ]

}
