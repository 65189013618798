<div class="section-full welcome-section-outer">
    <div class="welcome-section-top bg-white p-t80 p-b50">
        <div class="container">
            <div class="row d-flex justify-content-center">

                <div class="col-lg-12 col-md-12 m-b30">
                    <div class="welcom-to-section">
                        <!-- TITLE START-->
                        <div class="left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                                <div  class="sep-leaf-left"></div>
                                <div>{{data.title}}</div>
                                <div  class="sep-leaf-right"></div>
                            </div>
                        </div>
                        <h3>{{data.subtitle}}</h3>
                        <!-- TITLE END-->

                        <p>{{data.ceoMessage1}}</p>
                        <p>{{data.ceoMessage2}}</p>
                        <p>{{data.ceoMessage3}}</p>
                        <p>{{data.ceoMessage4}}</p>
                        <p>{{data.ceoMessage5}}</p>
                        <p>Regards,</p>
                        <p>Rajesh</p>

                        <div class="welcom-to-section-bottom d-flex justify-content-between">
                            <div class="welcom-btn-position"><a routerLink="/about" class="site-button-secondry site-btn-effect">More About us</a></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
