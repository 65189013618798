<div class="page-wraper">

    <!-- HEADER START -->
    <app-section-header></app-section-header>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- SERVICE DETAIL SECTION START -->
        <div class="section-full small-device  p-t80 p-b50 bg-white">
            <div class="section-content">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-4 col-md-12 rightSidebar">
                            <div class="all_services">
                                <ul>
                                    <li><a routerLink="/services/electrical-equipments-erection-and-commissioning" class="{{loadingurl == 'electrical-equipments-erection-and-commissioning' ? 'active' : ''}}">Electrical Equipments Erection and Commissioning</a></li>
                                    <li><a routerLink="/services/electro-mechanical-services" class="{{loadingurl == 'electro-mechanical-services' ? 'active' : ''}}">Electro Mechanical Services</a></li>
                                    <li><a routerLink="/services/supervision-services" class="{{loadingurl == 'supervision-services' ? 'active' : ''}}">Supervision Services</a></li>
                                    <li><a routerLink="/services/lifting-and-transportation-services" class="{{loadingurl == 'lifting-and-transportation-services' ? 'active' : ''}}">Lifting and Transportation Services</a></li>
                                    <li><a routerLink="/services/annual-maintenance-services" class="{{loadingurl == 'annual-maintenance-services' ? 'active' : ''}}">Annual Maintenance Services</a></li>
                                    <li><a routerLink="/services/retrofitting-of-electrical-equipments-services" class="{{loadingurl == 'retrofitting-of-electrical-equipments-services' ? 'active' : ''}}">Retrofitting of Electrical Equipments Services</a></li>
                                    <li><a routerLink="/services/operation-and-maintenance-services" class="{{loadingurl == 'operation-and-maintenance-services' ? 'active' : ''}}">Operation and Maintenance Services</a></li>
                                </ul>
                            </div>

                            <div class="service-side-btn m-b30 site-bg-secondry p-a20 text-white">
                               <h2 class="wt-title">Company Profile</h2>
                                <p>View our Company Profile for an easy to read guide on all of the services offer.</p>

                                <div class="wt-icon-box-wraper left ">
                                    <a href="assets/pdf/Prime_Electriks_and_Machinery_Services_Profile.pdf" download="Prime_Electriks_and_Machinery_Services_Profile.pdf" class="btn-block  p-a15 m-tb10">
                                        <span class="text-black m-r10"><i class="fa fa-file-pdf-o"></i></span>
                                        <strong class="text-uppercase text-black">Download Company Profile</strong>
                                    </a>
                                </div>
                                <h2 class="wt-title">Detailed Project</h2>
                                <p>View our detailed project PDF to understand how we approach project implementation.</p>
                                <div class="wt-icon-box-wraper left">
                                    <a href="assets/pdf/Solar_Power_Plant_Eattayapuram_Kovilpatti.pdf" download="Solar_Power_Plant_Eattayapuram_Kovilpatti.pdf" class="btn-block p-a15 m-t10">
                                        <span class="text-black m-r10"><i class="fa fa-file-powerpoint-o"></i></span>
                                        <strong class="text-uppercase text-black">Download Project Detail</strong>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-12">
                            <app-page-s-erection *ngIf="loadingurl == 's-erection'"></app-page-s-erection>
                            <app-page-s-automotive *ngIf="loadingurl == 's-automotive'"></app-page-s-automotive>
                            <app-page-s-chemical *ngIf="loadingurl == 's-chemical'"></app-page-s-chemical>
                            <app-page-s-civil *ngIf="loadingurl == 's-civil'"></app-page-s-civil>
                            <app-page-s-mechanical *ngIf="loadingurl == 's-mechanical'"></app-page-s-mechanical>
                            <app-page-s-oilgas *ngIf="loadingurl == 's-oilgas'"></app-page-s-oilgas>
                            <app-page-s-power-energy *ngIf="loadingurl == 's-power-energy'"></app-page-s-power-energy>
                            <app-page-s-electrical-equipments-and-commisioning *ngIf="loadingurl == 'electrical-equipments-erection-and-commissioning'"></app-page-s-electrical-equipments-and-commisioning>
                            <app-page-s-electro-mechanical-services *ngIf="loadingurl == 'electro-mechanical-services'"></app-page-s-electro-mechanical-services>
                            <app-page-s-supervision-services *ngIf="loadingurl == 'supervision-services'"></app-page-s-supervision-services>
                            <app-page-s-lifting-and-transportation-services *ngIf="loadingurl == 'lifting-and-transportation-services'"></app-page-s-lifting-and-transportation-services>
                            <app-page-s-annual-maintenance-services *ngIf="loadingurl == 'annual-maintenance-services'"></app-page-s-annual-maintenance-services>
                            <app-page-s-retrofitting-of-electrical-equipments-services *ngIf="loadingurl == 'retrofitting-of-electrical-equipments-services'"></app-page-s-retrofitting-of-electrical-equipments-services>
                            <app-page-s-operation-and-maintenance-services *ngIf="loadingurl == 'operation-and-maintenance-services'"></app-page-s-operation-and-maintenance-services>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SERVICE DETAIL SECTION END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-section-footer></app-section-footer>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>
