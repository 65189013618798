<div class="section-full" [style.backgroundColor]=data.backgroundColor>
    <div class="container">
      <!-- TITLE START-->
      <div class="section-head p-t30 center wt-small-separator-outer text-center {{overlay==1 ? 'text-white' : ''}}">
        <div class="wt-small-separator site-text-primary">
            <div  class="sep-leaf-left"></div>
            <div>{{data.title}}</div>
            <div  class="sep-leaf-right"></div>
        </div>
        <h2>{{data.subtitle}}</h2>
    </div>
    <!-- TITLE END-->
        <div class="section-content">

            <!-- TESTIMONIAL 4 START ON BACKGROUND -->
            <div class="section-content">
                 <div class="section-content p-tb30 owl-btn-vertical-center">
                    <div class="owl-carousel home-client-carousel-2">

                        <div class="item" *ngFor="let object of data.clients">
                            <div class="ow-client-logo">
                                <div class="client-logo client-logo-media">
                                <a routerLink="/about/about1"><img src="{{object}}" alt=""></a></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
