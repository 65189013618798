<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Electrical Equipments Erection and Commissioning</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
       <p>Prime Electriks provide ETC services ranging from project development support through mobilization services to contracts from Pre-commissioning, Commissioning and start-up of commercial operation, supporting the whole project life cycle. The dynamic contribution of employees is our immense potential.</p>
       <p>Our Engineers delivers high quality of services to fulfill customer needs. We deploy a certified engineers who are trained and experienced in performing specification of Erection and commissioning comply with industry standards.</p>
           <div class="wt-media m-b30">
               <img src="assets/images/services/services-electrical-equipments-erection-and-commisioning.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>Our Engineers undergone Electrical Safety Training, along with how to select and use the proper electrical protective equipment. They also participate in mandatory safety sessions & safety meetings as & when conducted. We also provide not only training, but properly selected, rated and tested electrical protective equipment.</p>
        </div>
    </div>
   <div class="service-provide">
           <h2>Brief of Components</h2>
        <div class="row">
        
            <div class="col-md-6">
                <ul class="list-angle-right">
                    <li>Current transformer</li>
                    <li>Potential transformer</li>
                    <li>Power and Distribution Transformer</li>
                    <li>Circuit Breaker</li>
                    <li>Isolator’s</li>
                    <li>Generator’s</li>
                </ul>
             </div> 
                                                       
            <div class="col-md-6">
                <div class="wt-media m-b30">
                    <img src="assets/images/services/services-electrical-equipments-erection-and-commisioning-1.jpg" alt=""> 
                </div>                                            
            </div>

        </div>
   </div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s Aditya Solar Pvt Ltd<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">Erection Testing and Commissioning of 2.5 MWE Solar power plant, TADA Industrial City</div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>