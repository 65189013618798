<footer class="site-footer footer-large footer-dark text-white footer-style1" >


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/footer-bg.png)">
        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-md-12 col-sm-12 footer-bg-grey">
                    <div class="footer-h-left">
                        <div class="widget widget_about">
                            <div class="logo-footer clearfix">
                                <a routerLink="/"><img src="assets/images/primeElectriksLogo.png" alt="" ></a>
                            </div>
                            <p>Prime Electriks and Machinery Services Pvt Limited is an certified company, which was founded in the year 2019 by as a Proprietorship firm and later converted to private limited company. The company is enlisted for highly reliable Engineers and workmen’s with working equipments.</p>
                         </div>
                        <div class="widget recent-posts-entry">
                           <ul class="widget_address">
                                <li><i class="fa fa-map-marker"></i>Prime Electriks and Machinery Services Pvt Limited<br>
                                    No.2/107E, Haridasapuram, Pottalkulam Road,<br>
                                    Variyoor Post, Kanyakumari District,<br>
                                    Tamil Nadu – 629 704.</li>
                                <li><i class="fa fa-envelope"></i>info@primeelectriks.com</li>
                                <li> <i class="fa fa-phone"></i><a href="tel:+919500855901">+91-9500855901</a></li>
                            </ul>
                        </div>
                        <ul class="social-icons  wt-social-links footer-social-icon">
                            <li><a href="https://www.google.com/search?gs_ssp=eJwFwUsOQDAUBdCYSuyhE6ZeP4myBLuo9paijbQi7N45ddOvPee7jikuXlI1tfTKhZSXoyDjhRYYJ3oH4WCsVo6IK-4wd1cOEQwn7J3DUZhJjkVjt5CQP1aQn2BRfiBnHhs&q=prime+electriks+and+machinery+services&rlz=1C1ONGR_enIN993IN993&oq=prime+elec&aqs=chrome.1.69i57j46i39i175i199j0i512l2j46i175i199i512j69i60j69i61l2.5801j0j7&sourceid=chrome&ie=UTF-8" target="_blank" class="fa fa-google"></a></li>
                            <li><a href="https://www.facebook.com/primeelectriksandmachineryservices" target="_blank" class="fa fa-facebook"></a></li>
                            <li><a href="https://www.linkedin.com/company/prime-electriks-and-machinery-services/" target="_blank" class="fa fa-linkedin"></a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="row footer-h-right">
                        <div class="col-lg-4 col-md-4">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Useful links</h3>
                                <ul>
                                    <li><a routerLink="/">Home</a></li>
                                    <li><a routerLink="/about">About Prime Electriks</a></li>
                                    <li><a routerLink="/projects/screenshots">Project Screenshots</a></li>
                                    <li><a routerLink="/contact">Contact Us</a></li>
                                    <li><a target="_self" href="https://app.primeelectriks.com">Employee Login</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-8">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Our Services</h3>
                                <ul>
                                    <li><a routerLink="/services/electrical-equipments-erection-and-commissioning">Electrical Equipments Erection and Commissioning</a></li>
                                    <li><a routerLink="/services/electro-mechanical-services">Electro Mechanical</a></li>
                                    <li><a routerLink="/services/supervision-services">Supervision</a></li>
                                    <li><a routerLink="/services/lifting-and-transportation-services">Lifting and Transportation</a></li>
                                    <li><a routerLink="/services/annual-maintenance-services">Annual Maintenance</a></li>
                                    <li><a routerLink="/services/retrofitting-of-electrical-equipments-services">Retrofitting of Electrical Equipments</a></li>
                                    <li><a routerLink="/services/operation-and-maintenance-services">Operation and Maintenance</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-8">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Our Great Works</h3>
                                <ul>
                                    <li><a routerLink="/projects/kudankulam-nuclear-power">Kudankulam Nuclear Power Project</a></li>
                                    <li><a routerLink="/projects/eattayapuram-solar-power">Eattayapuram Solar Power Plant</a></li>
                                    <li><a routerLink="/projects/kudankulam-hydro-technical-structure">Kudankulam Hydro Technical Structure</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
      <div class="container">
        <div class="wt-footer-bot-left text-center justify-content-between">
            <span class="copyrights-text">Copyright © 2025 Prime Electriks and Machinery Services Pvt Limited</span>
        </div>
      </div>
    </div>


</footer>
