import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css']
})
export class PageHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  specifications = [
    {
      title1: "Electrical Equipments",
      title2: "Erection and Commissioning",
      description: "Prime provide ETC services ranging from project development support through mobilization services to contracts from...",
      icon: "flaticon-industry",
      serial: "01",
      pagelink: "services/electrical-equipments-erection-and-commissioning"
    },
    {
      title1: "Electro Mechanical",
      title2: "Services",
      description: "Prime Electriks and Machinery Services provide complete installation, maintenance, testing and reassurance services...",
      icon: "flaticon-conveyor",
      serial: "02",
      pagelink: "services/electro-mechanical-services"
    },
    {
      title1: "Supervision",
      title2: "Services",
      description: "Planning, constructing, operating, and maintaining cable systems for high- and ultra-high-voltage power transmission...",
      icon: "flaticon-helmet",
      serial: "03",
      pagelink: "services/supervision-services"
    },
    {
      title1: "Lifting and",
      title2: "Transportation",
      description: "Prime Electriks deliver our services up to power machineries cranes for lifting heavy electrical components...",
      icon: "flaticon-robotic-arm",
      serial: "04",
      pagelink: "services/lifting-and-transportation-services"
    },
    {
      title1: "Annual",
      title2: "Maintenance",
      description: "Strong Technical skills to carry out Erection, Testing, Commissioning, Maintenance, Operation, Project Management...",
      icon: "flaticon-repair",
      serial: "05",
      pagelink: "services/annual-maintenance-services"
    },
    {
      title1: "Operation and",
      title2: "Maintenancce",
      description: "Our engineers also professionalized in pre-commissioning and commissioning of various process plant equipments like...",
      icon: "flaticon-layers",
      serial: "06",
      pagelink: "services/operation-and-maintenance-services"
    }
  ]

  projects = {
    title: "Explore Our Recent Work",
    subtitle: "We've Done lot's Projects Let's Check Some Here",
    bgImage: "assets/images/home-page/home-page-all-services-bg.jpg",
    projects: [
      {
        category: "electrical-equipments-erection-and-commissioning",
        image: "assets/images/home-page/home-page-services-electrical-commisioning.jpg",
        title: "M/S Aditya Solar",
        pagelink: "services/electrical-equipments-erection-and-commissioning"
      },
      {
        category: "electro-mechanical-services",
        image: "assets/images/home-page/home-page-services-electro-mechanical.jpg",
        title: "M/S Larsen & Toubro",
        pagelink: "services/electro-mechanical-services"
      },
      {
        category: "lifting-and-transportation-services",
        image: "assets/images/home-page/home-page-services-lifting-and-transportation-1.jpg",
        title: "M/S Reliance Infra Limited",
        pagelink: "services/lifting-and-transportation-services"
      },
      {
        category: "supervision-services",
        image: "assets/images/home-page/home-page-services-supervison-services.jpg",
        title: "M/S BGR Energy Systems",
        pagelink: "services/supervision-services"
      },
      {
        category: "lifting-and-transportation-services",
        image: "assets/images/home-page/home-page-services-lifting-and-transportation-2.jpg",
        title: "M/S Macmet Engineering Ltd",
        pagelink: "services/lifting-and-transportation-services"
      },
      {
        category: "annual-maintenance-services",
        image: "assets/images/home-page/home-page-services-annual-maintenance-services-1.jpg",
        title: "M/S Kumararaja Projects Ltd",
        pagelink: "services/annual-maintenance-services"
      },
      {
        category: "annual-maintenance-services",
        image: "assets/images/home-page/home-page-services-annual-maintenance-services-2.jpg",
        title: "M/S Reliance Infra Ltd",
        pagelink: "services/annual-maintenance-services"
      },
      {
        category: "operation-and-maintenance-services",
        image: "assets/images/home-page/home-page-services-operation-and-maintenance-services.jpg",
        title: "M/S Larsen & Toubro Ltd",
        pagelink: "services/operation-and-maintenance-services"
      }
    ]
  }

  about = {
    title: "Welcome to Prime Electriks and Machinery Services",
    subtitle: "Dear and Esteemed customer,",
    since: "2015",
    ceoMessage1: "Imagine a situation where you are consistently maximizing the profitability of your industrial and commercial assets while being able to flexibly adapt your operations over time with changes in the desired outcomes. Think how it would benefit you if you were able to apply the most advanced technology combined with long term financial predictability.",
    ceoMessage2: "Prime Electriks and Machinery Services aims to address exactly those needs, by providing the hassle-free transactions that can relieve the owners of the stress and strain of day-to-day activities.",
    ceoMessage3: "Our core proficiency remains our extensive experience and specialization in the areas of power infrastructure. Our areas of experience and service also extend to refineries and petrochemicals, sugar industry, oil & gas, steel plants, utilities, off-sites as well as renewable energy power plants. While reducing the costs at the customer's end, we deliver safe solutions and reliable expertise of services to run efficiently.",
    ceoMessage4: "We understand our clients' need for reliable, relentless service that's keeps accountability high and revenue on an upswing. Our experience in the field for over two decades has given us the high level of industrial wisdom and personnel expertise that would allow us to attend to your needs around the clock.",
    ceoMessage5: "Our organization has been created by the building blocks of hard work, persistence and an obsession for quality. We have never shirked from challenges, and have continuously outdone our efforts at being the best in the field.",
  }

  clients = {
    backgroundColor: "#FFFFFF",
    title: "Our Clients",
    subtitle: "Look at the our past clients we have worked with!",
    clients: [
      "assets/images/client-logo/l_and_t.png",
      "assets/images/client-logo/bgr_energy.png",
      "assets/images/client-logo/reliance_infra.png",
      "assets/images/client-logo/tangedco.png",
      "assets/images/client-logo/siemens.png",
      "assets/images/client-logo/department_of_atomic_energy.svg",
      "assets/images/client-logo/bhel.png",
      "assets/images/client-logo/tata_projects.jpg"
    ]
  }

  testimonials = {
    title: "What our client say",
    subtitle: "Happy With Customers & Clients",
    quotesTitle: "Testimonial",
    quotes: [
      {
        image: "assets/images/client-logo/reliance_infra.png",
        quote: "M/s Prime Electriks taken Turmuenous effect for our flooded  500 KVA Diesel generator retrofitting and renovation. Now our DG came good reliable operation. Also our DG was working properly before in deficiency.",
        name: "Er.Behra",
        post: "Reliance Infra- UTPS"
      },
      {
        quote: "M/s Prime Company hopefully completed a underground Earth grid for 230KV and 400KV Substation by competent engineers. Thanks Prime Electriks and Machinery Services. Wish them for good luck!",
        image: "assets/images/client-logo/bgr_energy.png",
        name: "BGR Energy Systems",
        post: "NPCIL,KKNPP"
      },
      {
        quote: "M/s Prime perfectly did the co-ordination of relay and ratio of transformers commissioning with technical coordination of our client. Thanks Prime Electriks and Machinery Services. Good Luck for your future!",
        image: "assets/images/client-logo/l_and_t.png",
        name: "Deepak Varma",
        post: "L & T Infrastructure"
      },
      {
        quote: "M/s Prime Electriks and Machinery services well equipped contractor. Supportively completed our compact 33KV Substation project in committed time. Thanks Prime Electriks and Machinery Services! Wish them for success!",
        image: "assets/images/client-logo/tangedco.png",
        name: "Payan Krishnaraj",
        post: "Superintent Engineer-TANDEDCO"
      }
    ]
  }

  team = {
    title: "Our Great Works",
    subtitle: "We will serve you with the best of our capacity by expert team",
    bgImage: "assets/images/background/bg-map.png",
    members: [
      {
        image: "assets/images/home-page/home-page-our-great-works-kudankulam-nuclear-power-plant.jpg",
        name: "Kudankulam Nuclear Power Plant",
        post: "Nuclear Power Plant",
        redirectionLink: "projects/kudankulam-nuclear-power"
      },
      {
        image: "assets/images/home-page/home-page-services-electrical-commisioning.jpg",
        name: "Eattayapuram 230 MWE Solar Power Plant",
        post: "Solar Power Plant",
        redirectionLink: "projects/eattayapuram-solar-power"
      },
      {
        image: "assets/images/home-page/home-page-our-great-works-kudankulam-hydro-technical-structure.jpg",
        name: "Kudankulam Hydro Technical Structure",
        post: "Hydro Electric Plant",
        redirectionLink: "projects/kudankulam-hydro-technical-structure"
      }
    ]
  }

  selfIntro = {
    image: "assets/images/home-page/home-page-industrial-worker.png",
    bgImage: "assets/images/background/footer-bg.png",
    bgClass: "bg-gray",
    bgCover: "",
    opacity: "opacity-09",
    imageClass: "self-intro-pic-block"
  }
}
