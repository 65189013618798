import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-eattayapuram-solar-power-plant',
  templateUrl: './page-project-eattayapuram-solar-power-plant.component.html',
  styleUrls: ['./page-project-eattayapuram-solar-power-plant.component.css']
})
export class PageProjectEattayapuramSolarPowerPlantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/banner-solar-power-plant.jpg",
    title: "Eattayapuram Solar Power Plant",
    crumb: "Eattayapuram Solar Power Plant"
  }

  images = [
    "assets/images/project/project-eattayapuram-solar-power.jpg"
  ]

  details = {
    project: "Solar Power Plant",
    category: "Installation & Maintenance",
    time: "2 Years",
    status: "In Porgress"
  }

  projectImages = [
    {
      image: "assets/images/projects/eattayapuram-solar/1.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/2.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/3.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/4.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/5.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/6.jpg"
    },
    {
      image: "assets/images/projects/eattayapuram-solar/7.jpg"
    }
  ]

}
