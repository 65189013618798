import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHomeComponent } from './pages/home/page-home/page-home.component';

import { SectionHeaderComponent } from './sections/header/section-header/section-header.component';
import { SectionFooterComponent } from './sections/footer/section-footer/section-footer.component';
import { SectionSlider1Component } from './sections/home/home1/section-slider1/section-slider1.component';
import { SectionAbout1Component } from './sections/home/home1/section-about1/section-about1.component';
import { SectionServices1Component } from './sections/home/home1/section-services1/section-services1.component';
import { SectionWhatWeDo1Component } from './sections/home/home1/section-what-we-do1/section-what-we-do1.component';
import { SectionVideo1Component } from './sections/home/home1/section-video1/section-video1.component';
import { SectionProjects1Component } from './sections/home/home1/section-projects1/section-projects1.component';
import { SectionTeam1Component } from './sections/home/home1/section-team1/section-team1.component';
import { SectionTestimonials1Component } from './sections/home/home1/section-testimonials1/section-testimonials1.component';
import { SectionBlogs1Component } from './sections/home/home1/section-blogs1/section-blogs1.component';
import { SectionClients1Component } from './sections/home/home1/section-clients1/section-clients1.component';
import { SectionGetInTouchComponent } from './sections/header/section-get-in-touch/section-get-in-touch.component';
import { SectionSlider2Component } from './sections/home/home2/section-slider2/section-slider2.component';
import { SectionSpecifications1Component } from './sections/home/home2/section-specifications1/section-specifications1.component';
import { SectionProjects2Component } from './sections/home/home2/section-projects2/section-projects2.component';
import { SectionAbout2Component } from './sections/home/home2/section-about2/section-about2.component';
import { SectionVideo2Component } from './sections/home/home2/section-video2/section-video2.component';
import { SectionTestimonials2Component } from './sections/home/home2/section-testimonials2/section-testimonials2.component';
import { SectionTeam2Component } from './sections/home/home2/section-team2/section-team2.component';
import { SectionSelfIntro1Component } from './sections/home/home2/section-self-intro1/section-self-intro1.component';
import { SectionBlogs2Component } from './sections/home/home2/section-blogs2/section-blogs2.component';
import { SectionSlider3Component } from './sections/home/home3/section-slider3/section-slider3.component';
import { SectionProjects3Component } from './sections/home/home3/section-projects3/section-projects3.component';
import { SectionSpecifications2Component } from './sections/home/home3/section-specifications2/section-specifications2.component';
import { SectionVideo3Component } from './sections/home/home3/section-video3/section-video3.component';
import { SectionPlans1Component } from './sections/home/home3/section-plans1/section-plans1.component';
import { SectionCounter1Component } from './sections/home/home3/section-counter1/section-counter1.component';
import { SectionTestimonials3Component } from './sections/home/home3/section-testimonials3/section-testimonials3.component';
import { SectionSlider4Component } from './sections/home/home4/section-slider4/section-slider4.component';
import { SectionWhatWeDo2Component } from './sections/home/home4/section-what-we-do2/section-what-we-do2.component';
import { SectionServices2Component } from './sections/home/home4/section-services2/section-services2.component';
import { SectionCounter2Component } from './sections/home/home4/section-counter2/section-counter2.component';
import { SectionVision1Component } from './sections/home/home4/section-vision1/section-vision1.component';
import { SectionBlogs3Component } from './sections/home/home4/section-blogs3/section-blogs3.component';
import { SectionGallery1Component } from './sections/home/home4/section-gallery1/section-gallery1.component';
import { SectionSlider5Component } from './sections/home/home5/section-slider5/section-slider5.component';
import { SectionServices3Component } from './sections/home/home5/section-services3/section-services3.component';
import { SectionProjects4Component } from './sections/home/home5/section-projects4/section-projects4.component';
import { SectionBlogs4Component } from './sections/home/home5/section-blogs4/section-blogs4.component';
import { SectionSlider6Component } from './sections/home/home6/section-slider6/section-slider6.component';
import { SectionServices4Component } from './sections/home/home6/section-services4/section-services4.component';
import { SectionAbout3Component } from './sections/home/home6/section-about3/section-about3.component';
import { SectionHowWeWork1Component } from './sections/home/home6/section-how-we-work1/section-how-we-work1.component';
import { SectionVideo4Component } from './sections/home/home6/section-video4/section-video4.component';
import { SectionBlogs5Component } from './sections/home/home6/section-blogs5/section-blogs5.component';
import { SectionPlans2Component } from './sections/home/home6/section-plans2/section-plans2.component';
import { SectionSlider7Component } from './sections/home/home7/section-slider7/section-slider7.component';
import { SectionWelcome1Component } from './sections/home/home7/section-welcome1/section-welcome1.component';
import { SectionWhatWeDo3Component } from './sections/home/home7/section-what-we-do3/section-what-we-do3.component';
import { SectionServices5Component } from './sections/home/home7/section-services5/section-services5.component';
import { SectionProjects5Component } from './sections/home/home7/section-projects5/section-projects5.component';
import { SectionVision2Component } from './sections/home/home7/section-vision2/section-vision2.component';
import { SectionAbout4Component } from './sections/home/home8/section-about4/section-about4.component';
import { SectionServices6Component } from './sections/home/home8/section-services6/section-services6.component';
import { SectionVideo5Component } from './sections/home/home8/section-video5/section-video5.component';
import { SectionProjects6Component } from './sections/home/home8/section-projects6/section-projects6.component';
import { SectionTestimonials4Component } from './sections/home/home8/section-testimonials4/section-testimonials4.component';
import { SectionBlogs6Component } from './sections/home/home8/section-blogs6/section-blogs6.component';
import { SectionSlider9Component } from './sections/home/home9/section-slider9/section-slider9.component';
import { SectionAbout5Component } from './sections/home/home9/section-about5/section-about5.component';
import { SectionServices7Component } from './sections/home/home9/section-services7/section-services7.component';
import { SectionQuality1Component } from './sections/home/home9/section-quality1/section-quality1.component';
import { SectionTeam3Component } from './sections/home/home9/section-team3/section-team3.component';
import { SectionProjects7Component } from './sections/home/home9/section-projects7/section-projects7.component';
import { SectionBlogs7Component } from './sections/home/home9/section-blogs7/section-blogs7.component';
import { SectionSlider10Component } from './sections/home/home10/section-slider10/section-slider10.component';
import { SectionCallUs1Component } from './sections/home/home10/section-call-us1/section-call-us1.component';
import { SectionAbout6Component } from './sections/home/home10/section-about6/section-about6.component';
import { SectionProjects8Component } from './sections/home/home10/section-projects8/section-projects8.component';
import { SectionServices8Component } from './sections/home/home10/section-services8/section-services8.component';
import { SectionQuality2Component } from './sections/home/home10/section-quality2/section-quality2.component';
import { SectionTeam4Component } from './sections/home/home10/section-team4/section-team4.component';
import { SectionTestimonials5Component } from './sections/home/home10/section-testimonials5/section-testimonials5.component';
import { SectionSlider11Component } from './sections/home/home11/section-slider11/section-slider11.component';
import { SectionAbout7Component } from './sections/home/home11/section-about7/section-about7.component';
import { SectionWhatWeDo4Component } from './sections/home/home8/section-what-we-do4/section-what-we-do4.component';
import { SectionWhatWeDo5Component } from './sections/home/home11/section-what-we-do5/section-what-we-do5.component';
import { SectionServices9Component } from './sections/home/home11/section-services9/section-services9.component';
import { SectionTeam5Component } from './sections/home/home11/section-team5/section-team5.component';
import { SectionGetInTouch1Component } from './sections/home/home11/section-get-in-touch1/section-get-in-touch1.component';
import { SectionClients2Component } from './sections/home/home11/section-clients2/section-clients2.component';
import { SectionProjects9Component } from './sections/home/home11/section-projects9/section-projects9.component';
import { SectionTestimonials6Component } from './sections/home/home11/section-testimonials6/section-testimonials6.component';
import { SectionBlogs9Component } from './sections/home/home11/section-blogs9/section-blogs9.component';
import { SectionBlogs8Component } from './sections/home/home10/section-blogs8/section-blogs8.component';
import { SectionSlider12Component } from './sections/home/home12/section-slider12/section-slider12.component';
import { SectionAbout8Component } from './sections/home/home12/section-about8/section-about8.component';
import { SectionFeatures1Component } from './sections/home/home12/section-features1/section-features1.component';
import { SectionServices10Component } from './sections/home/home12/section-services10/section-services10.component';
import { SectionCallUs2Component } from './sections/home/home12/section-call-us2/section-call-us2.component';
import { SectionContactUs1Component } from './sections/home/home12/section-contact-us1/section-contact-us1.component';
import { SectionTestimonials7Component } from './sections/home/home12/section-testimonials7/section-testimonials7.component';
import { SectionBlogs10Component } from './sections/home/home12/section-blogs10/section-blogs10.component';
import { SectionSlider8Component } from './sections/home/home8/section-slider8/section-slider8.component';
import { SectionInnerBannerComponent } from './sections/header/section-inner-banner/section-inner-banner.component';

import { PageServiceDetailComponent } from './pages/services/page-service-detail/page-service-detail.component';
import { PageProjectGridComponent } from './pages/project/page-project-grid/page-project-grid.component';
import { PageProjectMasonryComponent } from './pages/project/page-project-masonry/page-project-masonry.component';
import { PageProjectCarouselComponent } from './pages/project/page-project-carousel/page-project-carousel.component';
import { PageProjectDetailComponent } from './pages/project/page-project-detail/page-project-detail.component';
import { SectionServices11Component } from './sections/about/about1/section-services11/section-services11.component';
import { SectionPlans3Component } from './sections/services/services1/section-plans3/section-plans3.component';
import { SectionWhatWeDo6Component } from './sections/services/services2/section-what-we-do6/section-what-we-do6.component';
import { SectionServices12Component } from './sections/services/services2/section-services12/section-services12.component';
import { SectionVideo6Component } from './sections/about/about1/section-video6/section-video6.component';
import { SectionGallery2Component } from './sections/projects/carousel/section-gallery2/section-gallery2.component';
import { SectionGallery3Component } from './sections/projects/detail/section-gallery3/section-gallery3.component';
import { PagePricingComponent } from './pages/others/page-pricing/page-pricing.component';
import { PageIconFontsComponent } from './pages/others/page-icon-fonts/page-icon-fonts.component';
import { PageTeamComponent } from './pages/others/page-team/page-team.component';
import { PageTeamSingleComponent } from './pages/others/page-team-single/page-team-single.component';
import { PageFaqComponent } from './pages/others/page-faq/page-faq.component';
import { PageError403Component } from './pages/others/page-error403/page-error403.component';
import { PageError404Component } from './pages/others/page-error404/page-error404.component';
import { PageError405Component } from './pages/others/page-error405/page-error405.component';
import { PageContactUsComponent } from './pages/others/page-contact-us/page-contact-us.component';
import { ElementBlogBox1Component } from './elements/blogs/element-blog-box1/element-blog-box1.component';
import { LoaderComponent } from './elements/loader/loader.component';
import { LoaderDirective } from './directives/loader.directive';
import { SectionProjects10Component } from './sections/home/home6/section-projects10/section-projects10.component';
import { PageSElectricalEquipmentsAndCommisioningComponent } from './pages/services/page-s-electrical-equipments-and-commisioning/page-s-electrical-equipments-and-commisioning.component';
import { PageSElectroMechanicalServicesComponent } from './pages/services/page-s-electro-mechanical-services/page-s-electro-mechanical-services.component';
import { PageSSupervisionServicesComponent } from './pages/services/page-s-supervision-services/page-s-supervision-services.component';
import { PageSLiftingAndTransportationServicesComponent } from './pages/services/page-s-lifting-and-transportation-services/page-s-lifting-and-transportation-services.component';
import { PageSAnnualMaintenanceServicesComponent } from './pages/services/page-s-annual-maintenance-services/page-s-annual-maintenance-services.component';
import { PageSRetrofittingOfElectricalEquipmentsServicesComponent } from './pages/services/page-s-retrofitting-of-electrical-equipments-services/page-s-retrofitting-of-electrical-equipments-services.component';
import { PageSOperationAndMaintenanceServicesComponent } from './pages/services/page-s-operation-and-maintenance-services/page-s-operation-and-maintenance-services.component';
import { PageProjectKudankulamNuclearPowerComponent } from './pages/project/page-project-kudankulam-nuclear-power/page-project-kudankulam-nuclear-power.component';
import { PageProjectEattayapuramSolarPowerPlantComponent } from './pages/project/page-project-eattayapuram-solar-power-plant/page-project-eattayapuram-solar-power-plant.component';
import { PageProjectKudankulamHydroTechnicalStructureComponent } from './pages/project/page-project-kudankulam-hydro-technical-structure/page-project-kudankulam-hydro-technical-structure.component';
import { ProjectDetailedSectionGalleryComponent } from './sections/projects/carousel/project-detailed-section-gallery/project-detailed-section-gallery.component';
import { PageProjectScreenshotsComponent } from './pages/project/page-project-screenshots/page-project-screenshots.component';
import { ProjectScreenshotsSectionGalleryComponent } from './sections/projects/carousel/project-screenshots-section-gallery/project-screenshots-section-gallery.component';
import { PageContactComponent } from './pages/contact/page-contact/page-contact.component';
import { PageAboutComponent } from './pages/about/page-about/page-about.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHomeComponent,
    SectionHeaderComponent,
    SectionFooterComponent,
    SectionSlider1Component,
    SectionAbout1Component,
    SectionServices1Component,
    SectionWhatWeDo1Component,
    SectionVideo1Component,
    SectionProjects1Component,
    SectionTeam1Component,
    SectionTestimonials1Component,
    SectionBlogs1Component,
    SectionClients1Component,
    SectionGetInTouchComponent,
    SectionSlider2Component,
    SectionSpecifications1Component,
    SectionProjects2Component,
    SectionAbout2Component,
    SectionVideo2Component,
    SectionTestimonials2Component,
    SectionTeam2Component,
    SectionSelfIntro1Component,
    SectionBlogs2Component,
    SectionSlider3Component,
    SectionProjects3Component,
    SectionSpecifications2Component,
    SectionVideo3Component,
    SectionPlans1Component,
    SectionCounter1Component,
    SectionTestimonials3Component,
    SectionSlider4Component,
    SectionWhatWeDo2Component,
    SectionServices2Component,
    SectionCounter2Component,
    SectionVision1Component,
    SectionBlogs3Component,
    SectionGallery1Component,
    SectionSlider5Component,
    SectionServices3Component,
    SectionProjects4Component,
    SectionBlogs4Component,
    SectionSlider6Component,
    SectionServices4Component,
    SectionAbout3Component,
    SectionHowWeWork1Component,
    SectionVideo4Component,
    SectionBlogs5Component,
    SectionPlans2Component,
    SectionSlider7Component,
    SectionWelcome1Component,
    SectionWhatWeDo3Component,
    SectionServices5Component,
    SectionProjects5Component,
    SectionVision2Component,
    SectionAbout4Component,
    SectionServices6Component,
    SectionVideo5Component,
    SectionProjects6Component,
    SectionTestimonials4Component,
    SectionBlogs6Component,
    SectionSlider9Component,
    SectionAbout5Component,
    SectionServices7Component,
    SectionQuality1Component,
    SectionTeam3Component,
    SectionProjects7Component,
    SectionBlogs7Component,
    SectionSlider10Component,
    SectionCallUs1Component,
    SectionAbout6Component,
    SectionProjects8Component,
    SectionServices8Component,
    SectionQuality2Component,
    SectionTeam4Component,
    SectionTestimonials5Component,
    SectionSlider11Component,
    SectionAbout7Component,
    SectionWhatWeDo4Component,
    SectionWhatWeDo5Component,
    SectionServices9Component,
    SectionTeam5Component,
    SectionGetInTouch1Component,
    SectionClients2Component,
    SectionProjects9Component,
    SectionTestimonials6Component,
    SectionBlogs9Component,
    SectionBlogs8Component,
    SectionSlider12Component,
    SectionAbout8Component,
    SectionFeatures1Component,
    SectionServices10Component,
    SectionCallUs2Component,
    SectionContactUs1Component,
    SectionTestimonials7Component,
    SectionBlogs10Component,
    SectionSlider8Component,
    SectionInnerBannerComponent,
    PageServiceDetailComponent,
    PageProjectGridComponent,
    PageProjectMasonryComponent,
    PageProjectCarouselComponent,
    PageProjectDetailComponent,
    SectionServices11Component,
    SectionPlans3Component,
    SectionWhatWeDo6Component,
    SectionServices12Component,
    SectionVideo6Component,
    SectionGallery2Component,
    SectionGallery3Component,
    PagePricingComponent,
    PageIconFontsComponent,
    PageTeamComponent,
    PageTeamSingleComponent,
    PageFaqComponent,
    PageError403Component,
    PageError404Component,
    PageError405Component,
    PageContactUsComponent,
    ElementBlogBox1Component,
    LoaderComponent,
    LoaderDirective,
    SectionProjects10Component,
    PageSElectricalEquipmentsAndCommisioningComponent,
    PageSElectroMechanicalServicesComponent,
    PageSSupervisionServicesComponent,
    PageSLiftingAndTransportationServicesComponent,
    PageSAnnualMaintenanceServicesComponent,
    PageSRetrofittingOfElectricalEquipmentsServicesComponent,
    PageSOperationAndMaintenanceServicesComponent,
    PageProjectKudankulamNuclearPowerComponent,
    PageProjectEattayapuramSolarPowerPlantComponent,
    PageProjectKudankulamHydroTechnicalStructureComponent,
    ProjectDetailedSectionGalleryComponent,
    PageProjectScreenshotsComponent,
    ProjectScreenshotsSectionGalleryComponent,
    PageContactComponent,
    PageAboutComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    GoogleMapsModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
