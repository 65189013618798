<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Operation and Maintenance Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
        <p>Our engineers also professionalized in pre-commissioning and commissioning of various process plant equipments like Motors, Drives, Valve actuators, Hydraulic operated valves etc…</p>
        <div class="wt-media m-b30">
               <img src="assets/images/services/services-operations-and-maintenance-services.jpg" alt=""> 
        </div>
    </div>
    <div class="service-provide">
     <div class="row">
     
         <div class="col-md-12">
             <ul class="list-angle-right">
                 <li>Commissioning, Trouble shooting and calibration of solenoid valves by operating the solenoid and pneumatic.</li>
                 <li>Commissioning, Trouble shooting and calibration of control valves by raising and falling signals at 0, 25, 50, 75 & 100% of valve strokes.</li>
                 <li>Installation, commissioning and calibration of motorized valves by setting open close limit switches and torque switches for fully C/O position.</li>
             </ul>
          </div>

     </div>
    </div>
   <div class="service-provide">
           <h2>Motors</h2>
        <div class="row">
        
            <div class="col-md-6">
                <ul class="list-angle-right">
                    <li>High Tension Pump Motors</li>
                    <li>Low Tension Pump Motors</li>
                    <li>Valve actuator Motors</li>
                    <li>Doors actuator Motors</li>
                    <li>Ventilation Fan Motors</li>
                    <li>Hoist & Lifts</li>
                </ul>
             </div> 
                                                       
            <div class="col-md-6">
                <div class="wt-media m-b30">
                    <img src="assets/images/services/services-operations-and-maintenance-services-1.jpg" alt=""> 
                </div>                                            
            </div>

        </div>
   </div>

   <div class="service-provide">
    <h2>Valves</h2>
    <div class="row">
 
     <div class="col-md-6">
         <ul class="list-angle-right">
             <li>Various type of motorized valves</li>
             <li>Pneumatic Valves</li>
             <li>Solenoid Valves</li>
         </ul>
      </div> 
                                                
     <div class="col-md-6">
         <div class="wt-media m-b30">
             <img src="assets/images/services/services-operations-and-maintenance-services-2.jpg" alt=""> 
         </div>                                            
     </div>

 </div>
</div>

    <div class="service-provide">
        <h2>Cable Laying & Installation</h2>
        <div class="row">
 
     <div class="col-md-6">
         <ul class="list-angle-right">
             <li>Installation of all type of field instruments as per P&I drawings</li>
             <li>Installation of stanchions / supports and canopy for instruments</li>
             <li>Impulse tubing for instruments as per hook up drawings</li>
             <li>Laying and clamping of single par / multi core cables up to control room</li>
             <li>Gladding and cable termination in instruments, Junction boxes and Marshalling panels for DCS (or) PLC system.</li>
         </ul>
      </div> 
                                                
     <div class="col-md-6">
         <div class="wt-media m-b30">
             <img src="assets/images/services/services-operations-and-maintenance-services-3.jpg" alt=""> 
         </div>                                            
     </div>

 </div>
</div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s Larson & Toubro Ltd<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">
                <ul class="list-angle-right">
                    <li>11 KV /415 V Subsstation 04 Nos – Kudankulam Nuclear Power Project</li>
                    <li>Operation and Maintenance of 11 KV/415 V All Electrical System – Hydro Technical Structure, Kudankulam</li>
                    <li>11 KV/ 415 V Substation operation and Maintenance for Crusher plant, Heavy Material production plant and Painting yard.</li>
                </ul>
             </div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>