<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Lifting and Transportation Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
       <p>Prime Electriks deliver our services up to power machineries cranes for lifting heavy electrical components. Our machineries will be operated always follows industry safety aspects, safe work loads, signaling, competent holding riggers.</p>
       <p>Prime Electriks provide transporting vehicle’s with qualified and competent drivers for site execution also shifting heavy materials. With our contract lift, we take full control of the lift and provide the appointed person to carry out the lift. We will ensure that all aspects of the lifting project are professionally managed and completed to the highest industry standards.</p>
        <div class="wt-media m-b30">
               <img src="assets/images/services/services-lifting-and-transportation-services.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>We have built our business on recommendations and reliability, together with competitive pricing. All our drivers are fully qualified and equipped with real time telecommunications to ensure maximum flexibility in order to meet client needs. We strive to build and maintain our excellent reputation and carry out site surveys, lift plans, risk assessments and method statements specific to your site or lifting needs.</p>    
        </div>
    </div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s Reliance Infra Limited<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">Unloading and Stacking of Various Structural Steels.</div>
         </div>
     </div>
     <div class="panel wt-panel">
         <div class="acod-head">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseTwo5" class="collapsed" data-parent="#accordion5" >
                For M/s Macmet Engineering Ltd<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseTwo5" class="acod-body collapse bg-gray">
             <div class="acod-content p-a15">
                <ul class="list-angle-right">
                    <li>Unloading and Stacking of Various Structural Steels</li>
                    <li>Supervision Assistance of store quality clearance</li>
                    <li>Material Storage Managements</li>
                </ul>
             </div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>