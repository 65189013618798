import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-s-electro-mechanical-services',
  templateUrl: './page-s-electro-mechanical-services.component.html',
  styleUrls: ['./page-s-electro-mechanical-services.component.css']
})
export class PageSElectroMechanicalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  testimonials = {
    title: "What our client say",
    subtitle: "Happy With Customers & Clients",
    bgImage: "assets/images/background/bg-map.png",
    bgClass: "bg-center bg-white",
    quotes: [
      {
        quote: "M/s Prime Electriks taken Turmuenous effect for our flooded  500 KVA Diesel generator retrofitting and renovation. Now our DG came good reliable operation. Also our DG was working properly before in deficiency.",
        image: "assets/images/client-logo/reliance_infra.png",
        by: "Er.Behra",
        post: "Reliance Infra- UTPS"
      },
      {
        quote: "M/s Prime Company hopefully completed a underground Earth grid for 230KV and 400KV Substation by competent engineers.",
        image: "assets/images/client-logo/bgr_energy.png",
        by: "BGR Energy Systems",
        post: "NPCIL,KKNPP"
      },
      {
        quote: "M/s Prime perfectly did the co-ordination of relay and ratio of transformers commissioning with technical coordination of our client. Thanks Prime Electriks.",
        image: "assets/images/client-logo/l_and_t.png",
        by: "Deepak Varma",
        post: "L & T Infrastructure"
      },
      {
        quote: "M/s Prime Electriks and Machinery services well equipped contractor. Supportively completed our compact 33KV Substation project in committed time. . Wish them for success!",
        image: "assets/images/client-logo/tangedco.png",
        by: "Payan Krishnaraj",
        post: "Superintent Engineer-TANDEDCO"
      }
    ]
  }

}
