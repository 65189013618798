<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Electro Mechanical Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
        <p>Retrofitting of old type electro mechanical relays in to advanced numerical relays Replacement of old breakers & bus bars Modification of schemes.</p>
        <p>SOLAR POWER SYSTEMS </p>
        <p>Installation testing and commissioning of electrical equipments like Indoor Switchgear panels components. Testing of all DC equipments like HT Cables, Battery bank, Battery chargers, DCDB, DC String testing follows IR Test, Polarity Test, Floating current, Short circuit current, Open circuit voltage, DC Power measurement, Irradiation, Tem- perature (Ambient & Normal), IV Curve tracing, Earth resistance, etc.,</p>
        <div class="wt-media m-b30">
               <img src="assets/images/services/services-retrofiting-of-electrical-equipments.jpeg" alt=""> 
        </div>
        <div class="wt-info">
            <p>We provide the highest Quality as well as Professional Occupational Health & Safety Services to our Employees. To establish and meet the client's needs in terms of Occupational Health & Safety Management. To assist our clients in providing employees a healthy and safe working environment, free of hazards.</p>    
        </div>
    </div>
   <div class="service-provide">
           <h2>Safety Aspects</h2>
           <p>We, provide the highest Quality as well as Professional Occupational Health & Safety Services to our Employees. To establish and meet the client's needs in terms of Occupational Health & Safety Management. To assist our clients in providing employees a healthy and safe working environment, free of hazards.</p>
        <div class="row">
            <div class="col-md-6">
                <ul class="list-angle-right">
                    <li>Test all electrical and electronic components of the system before using it. Follow the instructions in the manuals supplied with the components and equipment. Commission and Maintenance works should only be performed by specialized and properly trained personnel.</li>
                    <li>Check the Open Circuit Voltage of every string of modules connected in series with a digital multi meter. The measured overall values should correspond to the sum of the Open Circuit Voltage of the individual modules.</li>
                    <li>Be aware that the measured overall voltage can be lower than expected, due the normal decrease of Open Circuit Voltage of the individual modules, caused by the temperature rising of solar cells or low irradiance.</li>
                </ul>
             </div> 
                                                       
            <div class="col-md-6">
                <div class="wt-media m-b30">
                    <img src="assets/images/services/services-retrofiting-of-electrical-equipments-1.jpg" alt=""> 
                </div>                                            
            </div>

        </div>
   </div>

   <div>                                 
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>