<div class="section-full  p-t80 p-b50 bg-white testimonial-2-outer bg-bottom-right bg-no-repeat" style="background-image:url(assets/images/background/bg-5.png)">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-6 col-md-12 m-b30">
                <!-- TITLE START-->
                <div class="left wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div  class="sep-leaf-left"></div>
                        <div>{{data.title}}</div>
                        <div  class="sep-leaf-right"></div>
                    </div>
                    <h2>{{data.subtitle}}</h2>
                </div>
                <!-- TITLE END-->

                <div class="testimonial-2-content-outer">
                    <div class="testimonial-2-content owl-carousel  owl-btn-bottom-right long-arrow-next-prev">
                        <div class="item" *ngFor="let object of data.quotes">
                            <div class="testimonial-2 bg-white">
                                <div class="testimonial-content">

                                    <div class="testimonial-text">
                                        <i class="fa fa-quote-left"></i>
                                        <p>{{object.quote}}</p>
                                    </div>
                                    <div class="testimonial-detail clearfix">
                                        <div class="testimonial-pic-block">
                                            <div class="testimonial-pic">
                                                <img src="{{object.image}}" alt="">
                                            </div>
                                        </div>
                                        <div class="testimonial-info">
                                            <span class="testimonial-name  title-style-2 site-text-secondry">{{object.name}}</span>
                                            <span class="testimonial-position title-style-2 site-text-primary">{{object.post}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6 col-md-12 m-b30">

                <!-- <div class="home-contact-section site-bg-primary m-b30 p-a40">
                    <form class="cons-contact-form" method="post" action="../../../../handlers/form-handler2.php">

                        <div class="wt-small-separator-outer text-white">
                            <h2>Feel free to get in touch!</h2>
                        </div>

                        <div class="row">
                        <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="username" type="text" required class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                <input name="email" type="text" class="form-control" required placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="phone" type="text" class="form-control" required placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="subject" type="text" class="form-control" required placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                <textarea name="message" class="form-control" rows="4" placeholder="Message"></textarea>
                                </div>
                            </div>

                        <div class="col-md-12">
                                <button type="submit" class="site-button-secondry site-btn-effect">Send us a message</button>
                            </div>

                        </div>
                </form>
                </div> -->

            </div>
    </div>
    </div>
    <div class="hilite-large-title title-style-2">
        <span>{{data.quotesTitle}}</span>
    </div>
</div>
