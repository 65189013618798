<header class="site-header header-style-2 mobile-sider-drawer-menu">

<div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">
            <div class="container clearfix">

                    <div class="logo-header">
                        <div class="logo-header-inner logo-header-one">
                            <a routerLink="/">
                            <img src="assets/images/primeElectriksLogo.png" alt="" />
                            </a>
                        </div>
                    </div>

                    <!-- NAV Toggle Button -->
                    <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar icon-bar-first"></span>
                        <span class="icon-bar icon-bar-two"></span>
                        <span class="icon-bar icon-bar-three"></span>
                    </button>

                    <!-- MAIN Vav -->
                    <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                        <ul class=" nav navbar-nav">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a href="javascript:void(0);">Our Services</a>
                                <ul class="sub-menu">
                                    <li><a routerLink="/services/electrical-equipments-erection-and-commissioning">Electrical Equipments Erection and Commissioning</a></li>
                                    <li><a routerLink="/services/electro-mechanical-services">Electro Mechanical Services</a></li>
                                    <li><a routerLink="/services/supervision-services">Supervision Services</a></li>
                                    <li><a routerLink="/services/lifting-and-transportation-services">Lifting and Transportation Services</a></li>
                                    <li><a routerLink="/services/annual-maintenance-services">Annual Maintenance Services</a></li>
                                    <li><a routerLink="/services/retrofitting-of-electrical-equipments-services">Retrofitting of Electrical Equipments Services</a></li>
                                    <li><a routerLink="/services/operation-and-maintenance-services">Operation and Maintenance Services</a></li>

                                </ul>
                            </li>
                           <li><a href="javascript:void(0);">Our Great Works</a>
                            <ul class="sub-menu">
                                <li><a routerLink="/projects/kudankulam-nuclear-power">Kudankulam Nuclear Power Project</a></li>
                                <li><a routerLink="/projects/eattayapuram-solar-power">Ettayapuram Solar Power Plant</a></li>
                                <li><a routerLink="/projects/kudankulam-hydro-technical-structure">Kudankulam Hydro Technical Structure</a></li>
                            </ul>
                          </li>
                          <li><a routerLink="/projects/screenshots">Project Screenshots</a></li>
                          <li><a routerLink="/contact">Contact Us</a></li>
                          <li><a href="assets/pdf/Prime_Electriks_and_Machinery_Services_Pvt_Ltd_Brocher.pdf" download="Prime_Electriks_and_Machinery_Services_Pvt_Ltd_Brocher.pdf">Download Brochure</a></li>
                          <li><a target="_self" href="https://app.primeelectriks.com">Employee Login</a></li>
                        </ul>

                    </div>

                </div>
            </div>

    </div>

</header>
