<div *ngFor="let project of projects">
  <div class="section-full p-t80 p-b80 overlay-wraper bg-cover">
    <div class="overlay-main bg-black opacity-07" [style.backgroundColor]=project.backgroundColor></div>
    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-white">
            <div class="wt-small-separator site-text-primary">
                <div class="sep-leaf-left"></div>
                <div>{{project.title}}</div>
                <div class="sep-leaf-right"></div>
            </div>
        </div>
        <!-- TITLE END-->
    </div>

    <div class="section-content container-fluid">

        <div class="owl-carousel gallery-slider owl-btn-vertical-center mfp-gallery">

            <div class="item" *ngFor="let object of project.cases">
                <div class="project-img-effect-1">
                    <img src="{{object.image}}" alt="" />
                   <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
                </div>
            </div>

        </div>

    </div>

  </div>

</div>
