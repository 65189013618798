<div class="section-full p-t80 p-b80 bg-gray">

  <div class="section-content container-fluid">

    <h2 class="text-center">Project Screenshots</h2>

      <div class="owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery">

          <div class="item" *ngFor="let object of projectImages">
              <div class="project-img-effect-1">
                  <img src="{{object.image}}" alt="" />
                 <a href="{{object.image}}" class="mfp-link"><i class="fa fa-search-plus"></i></a>
              </div>
          </div>

      </div>

  </div>

</div>
