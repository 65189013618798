<div class="section-content">
    <!-- TITLE START -->
    <div class="text-left">
        <h2 class="wt-title">Supervision Services</h2>
    </div>
    <!-- TITLE END -->                                
   <div class="wt-box services-etc m-b30">
        <p>Planning, constructing, operating, and maintaining cable systems for high- and ultra-high-voltage power transmission is a complex and demanding job. You can rely on us to take care of all these tasks in collaboration and consultation with you.</p>
        <p>Whether you’re a power generator, an end consumer, or a large-scale industrial customer, you need your electricity systems to operate reliably. Faults pose a threat to your power supply and can have significant consequences. The Emergency Response Solution from Prime Electriks gives you the assurance of fast and efficient repair of urgent faults – anywhere in the world!</p>
        <div class="wt-media m-b30">
               <img src="assets/images/services/services-supervision-services.jpg" alt=""> 
        </div>
        <div class="wt-info">
            <p>We are responsible for intervening if an emergency or crisis occurs as a result of grid problems or outages. We guarantee a quick response by our experts, followed by repairs and provision of the relevant spare parts as required.</p>    
        </div>
    </div>

   <div>
    <h2>Our Recent Works</h2>
 <div class="wt-accordion acc-bg-gray" id="accordion5">
     <div class="panel wt-panel">
         <div class="acod-head acc-actives">
              <h4 class="acod-title">
                 <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5" >
                    For M/s BGR Energy Systems<span class="indicator"><i class="fa fa-plus"></i></span>
                 </a>
              </h4>
         </div>
         <div id="collapseOne5" class="acod-body collapse show bg-gray">
             <div class="acod-content p-a15">
                <ul class="list-angle-right">
                    <li>Operation and Maintenance supervision</li>
                    <li>Equip potential grid works</li>
                    <li>Earthing copper conductor laying and Exothermic welding works Tarapur Atomic Power Project</li>
                </ul>
             </div>
         </div>
     </div>
 </div>                                   
</div> 
   
   <!-- TESTIMONIAL SECTION START -->
   <app-section-testimonials3 [data]="testimonials" whiteBox="0"></app-section-testimonials3>
   <!-- TESTIMONIAL SECTION END -->   
  
</div>