<div class="page-wraper">

  <!-- HEADER START -->
  <app-section-header></app-section-header>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">

      <!-- INNER PAGE BANNER -->
      <app-section-inner-banner [data]="banner"></app-section-inner-banner>
      <!-- INNER PAGE BANNER END -->

      <!-- ABOUT SECTION START -->
      <app-section-about1 [data]="about"></app-section-about1>
      <!-- ABOUT SECTION  SECTION END -->

      <!-- VISION SECTION START -->
      <app-section-vision1 [visions]="visions"></app-section-vision1>
      <!-- VISION SECTION END -->

      <!-- PROJECTS SECTION START -->
      <app-section-projects2 [data]="projects" overlay="0"></app-section-projects2>
      <!-- PROJECTS SECTION  SECTION END -->

      <!-- OUR TEAM SINGLE SECTION START -->
      <!-- TITLE START-->
      <div class="section-head center wt-small-separator-outer text-center {{overlay==1 ? 'text-white' : ''}}">
        <div class="wt-small-separator site-text-primary">
            <div  class="sep-leaf-left"></div>
            <div class="m-t50">Our Team</div>
            <div  class="sep-leaf-right"></div>
        </div>
        <h2>The Core People</h2>
    </div>
    <!-- TITLE END-->
      <div *ngFor="let team of teams">
        <div class="section-full p-t80 p-b50" [style.backgroundColor]=team.backgroundColor>
          <div class="container">
              <div class="section-content">

                      <div class="section-content">
                          <div class="row justify-content-center">
                              <div class="col-lg-4 col-md-4 m-b30">
                                  <div class="wt-team-1">

                                      <div class="wt-media">
                                          <img src="{{team.image}}" alt="">
                                      </div>

                                      <div class="wt-info p-a30  p-b0">
                                          <div class="team-detail  text-center">
                                              <h3 class="m-t0 team-name"><a class="site-text-secondry">{{team.name}}</a></h3>
                                              <span class="title-style-2 team-position site-text-primary">{{team.speciality}}</span>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                              <div class="col-lg-8 col-md-8 m-b30">
                                  <div class="wt-team-1-single">
                                      <h3 class="m-t0">About {{team.name}}</h3>
                                      <p>{{team.description}}</p>
                                      <ul class="wt-team-1-single-info bg-white">
                                          <li><span>Position</span><span>{{team.position}}</span></li>
                                          <li><span>Level</span><span>{{team.level}}</span></li>
                                          <li><span>Experience</span><span>{{team.experience}}</span></li>
                                          <li><span>Phone</span><span><a [href]="team.phoneLink">{{team.phone}}</a></span></li>
                                          <li><span>Email</span><span><a [href]="team.emailLink">{{team.email}}</a></span></li>
                                      </ul>

                                  </div>
                              </div>

                          </div>
                      </div>

              </div>
          </div>
      </div>
      </div>
    <!-- OUR TEAM SINGLE SECTION END -->

      <!-- SELF INTRO SECTION START -->
      <app-section-self-intro1 [data]="selfIntro"></app-section-self-intro1>
      <!-- SELF INTRO SECTION  END -->

      <!-- CLIENT LOGO SECTION START -->
      <app-section-clients1 [data]="clients"></app-section-clients1>
      <!-- CLIENT LOGO  SECTION End -->

  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->
  <app-section-footer></app-section-footer>
  <!-- FOOTER END -->

</div>

<app-loader></app-loader>
