import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-project-screenshots',
  templateUrl: './page-project-screenshots.component.html',
  styleUrls: ['./page-project-screenshots.component.css']
})
export class PageProjectScreenshotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/gallery/page-project-screenshot-banner.jpg",
    title: "Project Screenshots",
    crumb: "Project Screenshots"
  }

  projects = [
    {
      title: "525 MWe Thermal Power Plant",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/9.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/10.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/11.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/12.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/13.jpg"
        },
        {
          image: "assets/images/gallery/gallery-525-mwe-thermal-powerplant/14.jpg"
        }
      ]
    },
    {
      title: "Cable Laying and Termination",
      backgroundColor: "#f9f9f9",
      cases: [
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-cable-laying-and-termination/9.jpg"
        }
      ]
    },
    {
      title: "DG Erection and Maintenance",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/9.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/10.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/11.jpg"
        },
        {
          image: "assets/images/gallery/gallery-dg-erection-and-maintenance/12.jpg"
        }
      ]
    },
    {
      title: "Exothermic Welding",
      backgroundColor: "#f9f9f9",
      cases: [
        {
          image: "assets/images/gallery/gallery-exothermic-welding/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-exothermic-welding/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-exothermic-welding/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-exothermic-welding/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-exothermic-welding/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-exothermic-welding/6.jpg"
        }
      ]
    },
    {
      title: "Heavy Structure",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-heavy-structure/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-heavy-structure/7.jpg"
        }
      ]
    },
    {
      title: "High Mast",
      backgroundColor: "#f9f9f9",
      cases: [
        {
          image: "assets/images/gallery/gallery-high-mast/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/9.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/10.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/11.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/12.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/13.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/14.jpg"
        },
        {
          image: "assets/images/gallery/gallery-high-mast/15.jpg"
        }
      ]
    },
    {
      title: "Machinery Services",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-machinery-services/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-machinery-services/8.jpg"
        }
      ]
    },
    {
      title: "Sabari & Leon",
      backgroundColor: "#f9f9f9",
      cases: [
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/9.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/10.jpg"
        },
        {
          image: "assets/images/gallery/gallery-sabari-and-leon/11.jpg"
        }
      ]
    },
    {
      title: "Solar Aditya",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-solar-aditya/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/8.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/9.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/10.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-aditya/11.jpg"
        }
      ]
    },
    {
      title: "Solar Eattayapuram",
      backgroundColor: "#f9f9f9",
      cases: [
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-solar-eattayapuram/8.jpg"
        }
      ]
    },
    {
      title: "Substation",
      backgroundColor: "#FFFFFF",
      cases: [
        {
          image: "assets/images/gallery/gallery-substation/1.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/2.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/3.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/4.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/5.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/6.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/7.jpg"
        },
        {
          image: "assets/images/gallery/gallery-substation/8.jpg"
        }
      ]
    }
  ]

}
