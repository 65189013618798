<div class="page-wraper"> 
    
    <!-- HEADER START -->
    <app-section-header></app-section-header>
    <!-- HEADER END -->
    
    <!-- CONTENT START -->
    <div class="page-content">
    
        <!-- INNER PAGE BANNER -->
        <app-section-inner-banner [data]="banner"></app-section-inner-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- GALLERY SECTION START -->
        <app-section-gallery3 [data]="images"></app-section-gallery3>  
        <!-- GALLERY SECTION END -->
        
        <div class="section-full p-t80 p-b50">
            <div class="container">
                <div class="row d-flex justify-content-center flex-wrap"> 

                     <div class="col-lg-4 col-md-12 m-b30">
                        <div class="project-detail-left bg-gray p-a30">
                            
                            <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-tachometer"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Project</h3>
                                    <p>{{details.project}}</p>
                                </div>
                            </div>
                            
                            <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-list-alt"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Categories</h3>
                                    <p>{{details.category}}</p>
                                </div>
                            </div> 
                                                           
                            <div class="wt-icon-box-wraper left  m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-calendar"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Time</h3>
                                    <p>{{details.time}}</p>
                                </div>
                            </div>
                            
                            <div class="wt-icon-box-wraper left m-b15 p-b15 bdr-1 bdr-bottom bdr-gray">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-tags"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Status</h3>
                                    <p>{{details.status}}</p>
                                </div>
                            </div>  
                            
                            <div class="wt-icon-box-wraper left">
                                <div class="icon-sm m-b15">
                                    <span class="icon-cell  site-text-primary"><i class="fa fa-user"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h3 class="wt-tilte m-b5">Client</h3>
                                    <p>{{details.client}}</p>
                                </div>
                            </div>                                                                                                
                            
                        </div>                         	
                    </div>
                    
                     <div class="col-lg-8 col-md-12 m-b30">
                        <div class="project-detail-right">
                            <h2 class="m-t0">Chemical Refinery Information</h2>
                            <p>All this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, builder of human happiness. Great explorer of the truth, the master-builder of human happinessbut because those who do not to pursue seds.</p>
                            
                            <h3 class="m-t0">Successful Complete project </h3>
                            
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor imperdiet vehicula. Proin egestas diam ac mauris molestie hendrerit.</p> 
                            <ul class="list-check-circle primary">
                                <li>Words is roughly being maintained.</li>
                                <li>Previous casing is maintained.</li>
                                <li>Letters or begins with a capital letter.</li>
                            </ul>
                            
                            <h3 class="m-t0">Problem</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor imperdiet vehicula. Proin egestas diam ac mauris molestie hendrerit.</p>
                            
                            <h3 class="m-t0">Solution</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor imperdiet vehicula. Proin egestas diam ac mauris molestie hendrerit.</p> 
                            
                            <h3 class="m-t0">Process</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor imperdiet vehicula. Proin egestas diam ac mauris molestie hendrerit.</p>                                                                  
                                                              
                        </div> 
                    </div>

                </div>    
           </div>
        </div>                      
        
    </div>
    <!-- CONTENT END -->
    
    <!-- FOOTER START -->
    <app-section-footer></app-section-footer>
    <!-- FOOTER END -->
    
</div>

<app-loader></app-loader>
